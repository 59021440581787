<template>
  <div class="container with-footer mt-5">
    <div class="row">
      <div class="col-sm">
        <b-card class="card" header="Resetovať heslo">
          <div @keydown.enter="resetPassword()">
            <p>
              <strong>Overovací kód sme Vám odoslali na email. <br>
                Môžete ho sem vložiť, alebo pokračovať pomocou odkazu v emaile.</strong>
            </p>

            <form>
              <div class="row">
                <div class="form-group col-md-6 col-sm-12">
                  <label for="verificationCode">Verifikačný kód</label>
                  <input v-model="credentials.code" type="email" class="form-control" id="verificationCode" placeholder="Zadajte verifikačný kód ktorý bol odoslaný na Váš email"  autocomplete="none">
                </div>

                <div class="form-group col-md-6 col-sm-12">
                  <label for="emailAddress">Emailová adresa</label>
                  <input v-model="credentials.email" type="email" class="form-control" id="emailAddress" placeholder="Zadajte email"  autocomplete="email">
                </div>

                <div class="form-group col-md-6 col-sm-12">
                  <label for="newPassword">Nové heslo</label>
                  <input v-model="credentials.password" type="password" class="form-control" id="newPassword" placeholder="Zadajte nové heslo" autocomplete="new-password">
                </div>

                <div class="form-group col-md-6 col-sm-12">
                  <label for="passwordConfirmation">Potvrdenie hesla</label>
                  <input v-model="credentials.password_confirmation" type="password" class="form-control" id="passwordConfirmation" placeholder="Potvrdte nové heslo" autocomplete="new-password">
                </div>
              </div>
              <b-button variant="primary" class="mb-3" @click.prevent="resetPassword()" :disabled="$wToast.isLoading()">Zmeniť heslo</b-button>
            </form>

          </div>
        </b-card>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  wAuthGuard: true,
  props: ['navigateToAfterPasswordReset'],
  data() {
    return {
      credentials: {
        code: '',
        email: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  mounted() {
    this.credentials.email = this.$route.query.email
    this.credentials.code = this.$route.query.code
  },
  methods: {
    async resetPassword() {
      this.$wToast.clear_loading()


      await this.$store.dispatch('wAuth/resetPassword', this.credentials).then( async _ => {
        this.$wToast.stopLoading()
        await this.$router.push('/login')

      }).catch(e => this.$wToast.error(e))
    }
  }
}
</script>
